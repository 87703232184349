<!--
 * @Descripttion: 
 * @version: 1.0
 * @Author: horiz0n_Z
 * @Date: 2020-11-14 15:24:16
 * @LastEditors: horiz0n_Z
 * @LastEditTime: 2020-12-09 23:10:24
-->
<template>
  <div>
    <div class="notice">注：新密码请设置不少于8位数</div>
    <form action="" class="form">
      <h2 class="title">修改密码</h2>
      <div class="row">
        <div class="row__title">手机号</div>
        <input
          class="row__input"
          type="text"
          placeholder="输入联系电话"
          v-model="mobile"
        />
      </div>
      <div class="row">
        <div class="row__title">验证码</div>
        <input
          class="row__input"
          type="text"
          placeholder="请输入验证码"
          v-model="smsCode"
        />
        <button class="row__button" type="button" @click="sendCode">
          {{ smsMsg }}
        </button>
      </div>
      <div class="row">
        <div class="row__title">输入密码</div>
        <input
          class="row__input"
          type="password"
          placeholder="请输入新密码"
          v-model="password"
        />
      </div>
      <div class="row">
        <div class="row__title">重新输入</div>
        <input
          class="row__input"
          type="password"
          placeholder="请输入新密码"
          v-model="confirmPass"
        />
      </div>

      <button class="confirm" type="button" @click="confirm">确认修改</button>
    </form>
  </div>
</template>

<script>
import { resetPwd, sendSms } from "../../../utils/api/utils";
import { Toast } from "vant";
let smsInterval;
export default {
  data() {
    return {
      mobile: "",
      password: "",
      confirmPass: "",
      smsCode: "",

      smsMsg: "发送验证码",
    };
  },
  methods: {
    confirm() {
      resetPwd({
        mobile: this.mobile,
        password: this.password,
        confirmPass: this.confirmPass,
        smsCode: this.smsCode,
      })
        .then(() => {
          Toast.success("重置成功");
          window.localStorage.removeItem("token");
          this.$router.back();
        })
        .catch((res) => {
          Toast(res.data.msg);
        });
    },

    // 发送验证码
    sendCode() {
      let initSmsMsg = "发送验证码";
      if (this.smsMsg !== initSmsMsg) return false;

      sendSms({
        mobile: this.mobile,
      })
        .then(() => {})
        .catch((res) => {
          Toast(res.data.msg);
          this.clearCountDown();
        });

      let second = 60;

      let setSecond = function () {
        this.smsMsg = second;
        second--;
        if (second < 0) {
          this.clearCountDown();
        }
      }.bind(this);

      setSecond();

      smsInterval = setInterval(setSecond, 1000);
    },

    clearCountDown() {
      clearInterval(smsInterval);
      this.smsMsg = "发送验证码";
    },
  },
  beforeDestroy() {
    this.clearCountDown();
  },
};
</script>

<style src="@/assets/css/review/forget.css" scoped>
</style>